import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { extractElementPosition } from 'ng-html-util';

@Directive({
  selector: '[scroll-spy]',
})
export class ScrollSpyDirective implements AfterContentInit {
  private elements: any[] = [];
  private currentActiveLink: any;
  private directNavigation = false;

  // TODO: Change the any type to Document when fix https://github.com/angular/angular/issues/15640
  constructor(
    @Inject(DOCUMENT) private document: any,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngAfterContentInit(): void {
    this.collectIds();
  }

  private collectIds() {
    this.elements = [];
    let elements = this.el.nativeElement.querySelectorAll('a');

    for (let i = 0; i < elements.length; i++) {
      let elem = elements.item(i);

      let id = ScrollSpyDirective.getId(elem);
      if (!id) continue;

      let destination = this._getPeerElement(id);

      if (!destination) continue;

      elem.addEventListener('click', this._onLinkClicked.bind(this));

      this.elements.push({
        id,
        link: elem,
        destination,
      });
    }
  }

  /**
   * Native scrollTo with callback
   * @param offset - offset to scroll to
   * @param callback - callback function
   */
  private scrollTo(offset: any, callback: Function) {
    const fixedOffset = offset.toFixed();
    const onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener('scroll', onScroll);
        callback();
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }

  private _onLinkClicked(event: Event) {
    event.preventDefault();

    let target = event.currentTarget;
    let id = ScrollSpyDirective.getId(target);
    let destination = this._getPeerElement(id);
    this.directNavigation = true;

    let position = extractElementPosition(this.document, destination);

    this.scrollTo(position.top, () => {
      this._cleanCurrentLink();
      this._setCurrentLink(target);
      this.directNavigation = false;
    });
  }

  private _getPeerElement(id: any) {
    let destination = this.document.getElementById(id);

    if (!destination) return null;

    return destination;
  }

  private static getId(elem: any) {
    let href = elem.getAttribute('href');

    if (!href) return null;

    return href.replace('#', '');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    if (this.directNavigation) return;

    for (let elem of this.elements) {
      let top = elem.destination.getBoundingClientRect().top;
      if (top > 0 && top < 125) {
        this._cleanCurrentLink();
        this._setCurrentLink(elem.link);
        break;
      }
    }
  }

  private _cleanCurrentLink() {
    if (!this.currentActiveLink) return;

    this.renderer.removeClass(this.currentActiveLink, 'active');
  }

  private _setCurrentLink(elem: any) {
    this.currentActiveLink = elem;
    this.renderer.addClass(this.currentActiveLink, 'active');
  }
}
