<div class="fields__card {{ backgroundColor }}">
  <div class="fields__card__logo">
    <img src="assets/{{ imagePath }}" alt="image" />
  </div>
  <div class="fields__card__logoLight">
    <img src="assets/{{ lightImagePath }}" alt="image" />
  </div>
  <div class="fields__card__title mt-3 {{ color }}">{{ title }}</div>
  <div class="fields__card__text mt-3">
    {{ text }}
  </div>
</div>
