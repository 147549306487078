<div class="services pt-70">
  <div class="container">
    <div class="services__title text-center">
      {{ "services.title" | translate }}
    </div>
    <div class="row mt-5" [ngClass]="{ arabic: lang === 'ar' }">
      <div class="col-lg-4">
        <div class="services__item label" [ngClass]="{ arabic: lang === 'ar' }">
          <span>1</span><span>.</span> {{ "services.label1" | translate }}
        </div>
        <div class="services__item">{{ "services.item1" | translate }}</div>
        <div class="services__item">{{ "services.item2" | translate }}</div>
      </div>
      <div class="col-lg-4">
        <div class="services__item label" [ngClass]="{ arabic: lang === 'ar' }">
          <span>2</span><span>.</span> {{ "services.label2" | translate }}
        </div>
        <div class="services__item">{{ "services.item3" | translate }}</div>
        <div class="services__item">{{ "services.item4" | translate }}</div>
        <div class="services__item">{{ "services.item5" | translate }}</div>
      </div>
      <div class="col-lg-4">
        <div class="services__item label" [ngClass]="{ arabic: lang === 'ar' }">
          <span>3</span><span>.</span> {{ "services.label3" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
