import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { LangueService } from '../../shared/service/langue.service';

@Component({
  selector: 'app-growth',
  templateUrl: './growth.component.html',
  styleUrls: ['./growth.component.scss'],
})
export class GrowthComponent implements OnInit {
  options: AnimationOptions = {
    path: 'assets/images/lottie.json',
  };
  lang: any;

  animationCreated(animationItem: AnimationItem): void {
    animationItem.play();
  }

  constructor(
    public translate: TranslateService,
    public langueService: LangueService
  ) {}

  ngOnInit(): void {
    this.langueService.getLangue().subscribe((res) => {
      this.lang = res;
      if (this.lang === 'en') {
        this.options = {
          ...this.options,
          path: 'assets/images/lottie.json',
        };
      } else
        this.options = {
          ...this.options,
          path: 'assets/images/lottie-ar.json',
        };
    });
  }
}
