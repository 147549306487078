<div class="fields pt-70">
  <div class="container">
    <div class="fields__title text-center">
      {{ "fields.title" | translate }}
    </div>
    <div class="fields__subtitle text-center">
      {{ "fields.subtitle11" | translate }}
      <br />
      {{ "fields.subtitle12" | translate }}
    </div>
    <div class="row mt-5">
      <div class="col-lg-4 mt-3">
        <app-field-of-play
          title="{{ 'fields.1title' | translate }}"
          text="{{ 'fields.1text' | translate }}"
          imagePath="images/nama-health.svg"
          lightImagePath="images/nama-health-light.svg"
          color="purple"
          backgroundColor="bg-purple "
        ></app-field-of-play>
      </div>
      <div class="col-lg-4 mt-3">
        <app-field-of-play
          title="{{ 'fields.2title' | translate }}"
          text="{{ 'fields.2text' | translate }}"
          imagePath="images/nama-fintech.svg"
          lightImagePath="images/nama-fintech-light.svg"
          backgroundColor="bg-lightBlue"
        ></app-field-of-play>
      </div>
      <div class="col-lg-4 mt-3">
        <app-field-of-play
          title="{{ 'fields.3title' | translate }}"
          text="{{ 'fields.3text' | translate }}"
          imagePath="images/nama-real-estate.svg"
          lightImagePath="images/nama-realestate-light.svg"
          color="red"
          backgroundColor="bg-red"
        ></app-field-of-play>
      </div>
    </div>
  </div>
</div>
