<div class="contact pt-70">
  <div class="container">
    <div class="contact__title text-center">
      {{ "contact.title" | translate }}
    </div>

    <div class="row mt-5" [ngClass]="{ arabic: lang === 'ar' }">
      <div class="col-lg-7">
        <h4>{{ "contact.header" | translate }}</h4>
        <div class="d-flex">
          <form
            role="form"
            id="contact-form"
            class="contact-form w-100"
            (submit)="submitForm(contactForm)"
            #contactForm="ngForm"
          >
            <div class="row">
              <div
                class="nameInputs d-flex flex-row justify-content-end align-items-center w-100"
                [ngClass]="{ reverse: lang === 'ar' }"
              >
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      autocomplete="off"
                      id="name"
                      [(ngModel)]="name"
                      placeholder="{{ 'contact.name' | translate }}"
                      [ngClass]="{ nameError: nameError }"
                    />
                    <div class="errorMessage" *ngIf="nameError">
                      {{ "contact.nameError" | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="companyName"
                      autocomplete="off"
                      [(ngModel)]="companyName"
                      id="companyName"
                      placeholder="{{ 'contact.companyName' | translate }}"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    autocomplete="email"
                    [(ngModel)]="email"
                    id="email"
                    placeholder="{{ 'contact.email' | translate }}"
                    [ngClass]="{ emailError: emailError }"
                  />
                  <div class="errorMessage" *ngIf="emailError">
                    {{ "contact.emailError" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <textarea
                    class="form-control textarea"
                    rows="3"
                    name="message"
                    id="message"
                    [(ngModel)]="message"
                    placeholder="{{ 'contact.message' | translate }}"
                    [ngClass]="{ messageError: messageError }"
                  ></textarea>
                  <div class="errorMessage" *ngIf="messageError">
                    {{ "contact.messageError" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 d-flex justify-content-end">
                <button
                  type="submit"
                  class="btn main-btn"
                  [disabled]="submited"
                >
                  <span *ngIf="!submited" class="text-center">
                    {{ "contact.buttonText" | translate }}
                  </span>
                  <span *ngIf="submited" class="text-center">
                    {{ "contact.success" | translate }}
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-5 findUs">
        <div
          class="localisation d-flex justify-content-start align-items-center mt-3"
          [ngClass]="{ arabic: lang === 'ar' }"
        >
          <img src="assets/icons/localisation.svg" alt="localisation" />
          <div>{{ "contact.adress1" | translate }} <br /></div>
        </div>

        <div
          class="mail d-flex justify-content-start align-items-center mt-3"
          [ngClass]="{ arabic: lang === 'ar' }"
        >
          <img src="assets/icons/mail.svg" alt="mail" />
          <a href="mailto:info@Namainvest.sa">
            <div>{{ "contact.mail" | translate }}</div>
          </a>
        </div>
        <div class="map mt-5 row">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1810.7984816071842!2d46.6198726!3d24.8092508!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjTCsDQ4JzMyLjUiTiA0NsKwMzcnMDcuOCJF!5e0!3m2!1sen!2stn!4v1618923966368!5m2!1sen!2stn"
            width="100%"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
