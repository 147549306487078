import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LangueService } from '../../shared/service/langue.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  lang: any;
  constructor(public langueService: LangueService) {}

  ngOnInit(): void {
    this.langueService.getLangue().subscribe((res) => (this.lang = res));
  }
}
