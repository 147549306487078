import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/views/home/home.component';
import { HeaderComponent } from './modules/components/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GrowthComponent } from './modules/components/growth/growth.component';
import { ApproachComponent } from './modules/components/approach/approach.component';
import { ServicesComponent } from './modules/components/services/services.component';
import { FieldsComponent } from './modules/components/fields/fields.component';
import { TeamComponent } from './modules/components/team/team.component';
import { FieldOfPlayComponent } from './modules/shared/field-of-play/field-of-play.component';
import { TeamCardComponent } from './modules/shared/team-card/team-card.component';
import { ContactComponent } from './modules/components/contact/contact.component';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './modules/components/footer/footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { ScrollSpyDirective } from './modules/shared/directives/ng-scroll-spy.directive';

export function HttpLoaderfactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    GrowthComponent,
    ApproachComponent,
    ServicesComponent,
    FieldsComponent,
    TeamComponent,
    FieldOfPlayComponent,
    TeamCardComponent,
    ContactComponent,
    FooterComponent,
    ScrollSpyDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderfactory,
        deps: [HttpClient],
      },
    }),
    LottieModule.forRoot({ player: playerFactory }),
    ScullyLibModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
