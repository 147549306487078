import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-of-play',
  templateUrl: './field-of-play.component.html',
  styleUrls: ['./field-of-play.component.scss'],
})
export class FieldOfPlayComponent implements OnInit {
  @Input() imagePath = '';
  @Input() lightImagePath = '';
  @Input() title = '';
  @Input() text = '';
  @Input() color = '';
  @Input() backgroundColor = '';

  constructor() {}

  ngOnInit(): void {}
}
