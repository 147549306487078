<div
  class="team__card d-flex flex-column justify-content-center align-items-center"
>
  <div class="team__card__image">
    <img src="assets/{{ imagePath }}" alt="image" />
  </div>

  <div class="team__card__name mt-1">{{ name }}</div>

  <div class="team__card__position mt-1">{{ position }}</div>

  <div class="team__card__description mt-3 text-center">
    {{ description }}
  </div>
  <div class="team__card__link mt-5">
    <a href="{{ linkedin }}" target="_blank">
      <img src="assets/icons/linkedin.svg" alt="linkedin" />
    </a>
    <a href="mailto:{{ mail }}" target="_blank">
      <img src="assets/icons/mail.svg" alt="mail" />
    </a>
  </div>
</div>
