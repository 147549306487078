import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LangueService {
  langue: BehaviorSubject<string>;

  constructor() {
    this.langue = new BehaviorSubject('en');
  }
  setlangue(lang: any) {
    this.langue.next(lang);
  }
  getLangue() {
    return this.langue.asObservable();
  }
}
