<div class="approach pt-70">
  <div class="container">
    <div
      class="d-flex flex-column jutsify-content-center align-items-center mt-5"
    >
      <div class="approach__title">
        {{ "approach.title" | translate }}
      </div>
      <div class="approach__text mt-1">
        {{ "approach.titleText11" | translate }} <br />
        {{ "approach.titleText12" | translate }}
      </div>
      <div class="approach__subtitle">
        {{ "approach.subtitle" | translate }}
      </div>
      <div
        class="approach__items mt-3 d-flex flex-row justify-content-center align-items-center"
        [ngClass]="{ arab: lang === 'ar' }"
      >
        <div
          class="round d-flex flex-column justify-content-center align-items-center"
        >
          <img src="assets/icons/carbon_idea.svg" alt="ideate" />
          <div class="mt-1">{{ "approach.ideate" | translate }}</div>
        </div>
        <div
          class="round d-flex flex-column justify-content-center align-items-center"
        >
          <img src="assets/icons/valid.svg" alt="validate" />
          <div class="mt-1">{{ "approach.validate" | translate }}</div>
        </div>

        <div
          class="round d-flex flex-column justify-content-center align-items-center"
        >
          <img src="assets/icons/plant.svg" alt="plant" />
          <div class="mt-1">{{ "approach.grow" | translate }}</div>
        </div>
        <div
          class="round d-flex flex-column justify-content-center align-items-center"
        >
          <img src="assets/icons/valid.svg" alt="sustain" />
          <div class="mt-1">{{ "approach.sustain" | translate }}</div>
        </div>
      </div>
      <div class="approach__text mt-3">
        {{ "approach.text11" | translate }} <br />
        {{ "approach.text12" | translate }}
      </div>
      <div class="approach__stakeholders mt-5 flex-wrap">
        <img
          src="../../../../assets/images/Stackeholders-En.svg"
          alt="approach"
          *ngIf="lang !== 'ar'"
        />
        <img
          src="../../../../assets/images/Stackeholders-Ar.svg"
          alt="approach"
          *ngIf="lang === 'ar'"
        />
      </div>
    </div>
  </div>
</div>
