import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangueService } from '../../shared/service/langue.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  lang: any;
  constructor(
    public translate: TranslateService,
    public langueService: LangueService
  ) {}

  ngOnInit(): void {
    this.langueService.getLangue().subscribe((res) => (this.lang = res));
  }
}
