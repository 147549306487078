import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangueService } from '../../shared/service/langue.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public isMenuCollapsed = true;
  lang: any;
  constructor(
    public translate: TranslateService,
    public langService: LangueService
  ) {}

  ngOnInit(): void {}
  selectlang() {
    let val = this.lang === 'ar' ? 'en' : 'ar';
    this.translate.use(val);
    this.langService.setlangue(val);
    this.lang = val;
  }
  active(event: any) {
    var items = document.querySelectorAll('.nav-link');
    for (var i = 0; i < items.length; i++) {
      items[i].classList.remove('active');
    }
    event.target.classList.add('active');
  }
}
