<div class="nama" [ngClass]="{ arab: lang == 'ar' }">
  <div class="header" id="header">
    <app-header></app-header>
  </div>
  <div id="growth">
    <app-growth></app-growth>
  </div>
  <div id="approach">
    <app-approach></app-approach>
  </div>
  <div id="services">
    <app-services></app-services>
  </div>
  <div id="fields">
    <app-fields></app-fields>
  </div>
  <div id="team">
    <app-team></app-team>
  </div>
  <div id="contact">
    <app-contact></app-contact>
  </div>
  <div id="footer">
    <app-footer></app-footer>
  </div>
</div>
