<div class="header">
  <div class="container" [ngClass]="{ arabic: lang === 'ar' }">
    <nav class="navbar navbar-expand-lg mb-3">
      <div class="container" [ngClass]="{ arabic: lang === 'ar' }">
        <a class="navbar-brand" href="#">
          <img src="assets/images/logo.svg" alt="nama-logo" />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          (click)="isMenuCollapsed = !isMenuCollapsed"
        >
          &#9776;
        </button>

        <div
          [ngClass]="{ arabic: lang === 'ar' }"
          [ngbCollapse]="isMenuCollapsed"
          class="collapse navbar-collapse justify-content-end align-items-center"
        >
          <ul
            class="navbar-nav justify-content-end align-items-center"
            [ngClass]="{ arabic: lang === 'ar' }"
            scroll-spy
          >
            <li class="nav-item">
              <a
                class="nav-link"
                href="#growth"
                (click)="isMenuCollapsed = true; active($event)"
                >{{ "header.about" | translate }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#approach"
                (click)="isMenuCollapsed = true; active($event)"
                >{{ "header.approach" | translate }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#services"
                (click)="isMenuCollapsed = true; active($event)"
                >{{ "header.whatWeAre" | translate }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#team"
                (click)="isMenuCollapsed = true; active($event)"
                >{{ "header.team" | translate }}</a
              >
            </li>
            <!-- <li class="nav-item">
            <a class="nav-link" href="#team" (click)="isMenuCollapsed = true"
              >Advisory board</a
            >
          </li> -->
            <li
              class="nav-item lang d-flex flex-row justify-content-center align-items-center"
            >
              <div class="nav-link" (click)="selectlang()">
                {{ "header.lang" | translate }}
              </div>

              <!-- <a class="nav-link" href="#contact" (click)="isMenuCollapsed = true"
            >Contact</a -->
            </li>
            <li
              class="nav-item discover d-flex flex-row justify-content-center align-items-center"
            >
              <a
                class="nav-link nav-link__contact"
                href="#contact"
                (click)="isMenuCollapsed = true"
                >{{ "header.contact" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="header__introduction">
      <div class="header__introduction__title">
        {{ "header.introductionTitle11" | translate }} <br />
        {{ "header.introductionTitle12" | translate }}
      </div>
      <div class="header__introduction__subtitle mt-3">
        <span class="header__introduction__subtitle--colored">
          {{ "header.introductionSubtitleColored" | translate }}
        </span>
        {{ "header.introductionSubtitle2" | translate }}
        <span class="header__introduction__subtitle--colored">
          {{ "header.introductionSubtitle3Colored" | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
