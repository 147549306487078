import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LangueService } from '../../shared/service/langue.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  lang: any;
  submited = false;
  name = '';
  companyName = '';
  email = '';
  message = '';
  error: boolean = false;
  nameError: boolean = false;
  emailError: boolean = false;
  messageError: boolean = false;
  constructor(
    public translate: TranslateService,
    public http: HttpClient,
    public langueService: LangueService
  ) {}

  ngOnInit(): void {
    this.langueService.getLangue().subscribe((res) => (this.lang = res));
  }
  submitForm(contactForm: any) {
    if (this.name == '') {
      this.nameError = true;
    } else if (this.email == '') {
      this.emailError = true;
    } else if (this.message == '') {
      this.messageError = true;
    } else {
      this.submited = true;
      this.name = '';
      this.companyName = '';
      this.email = '';
      this.message = '';
    }
    if (contactForm.valid) {
      const form = contactForm.value;
      var formData = new FormData();
      formData.append('name', form.name);
      formData.append('email', form.email);
      formData.append('message', form.message);
      formData.append('company_name', form.companyName);

      this.http
        .post(
          'https://api-website.smala.co/api/namasendmessage',

          formData
        )
        .subscribe();
    }
  }
}
