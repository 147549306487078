import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss'],
})
export class TeamCardComponent implements OnInit {
  @Input() imagePath = '';
  @Input() name = '';
  @Input() position = '';
  @Input() description = '';
  @Input() linkedin = '';
  @Input() mail = '';

  constructor() {}

  ngOnInit(): void {}
}
