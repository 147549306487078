import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangueService } from '../../shared/service/langue.service';

@Component({
  selector: 'app-approach',
  templateUrl: './approach.component.html',
  styleUrls: ['./approach.component.scss'],
})
export class ApproachComponent implements OnInit {
  lang: any;
  constructor(
    public translate: TranslateService,

    public langueService: LangueService
  ) {}

  ngOnInit(): void {
    this.langueService.getLangue().subscribe((res) => (this.lang = res));
  }
}
