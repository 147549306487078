import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangueService } from './modules/shared/service/langue.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'nama invest';

  constructor(
    public translate: TranslateService,
    private titleService: Title,
    private metaService: Meta
  ) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
  }
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {
        name: 'keywords',
        content: 'invest,saoudi arabia,investing,nama,namainvest,nama invest, ',
      },
      {
        name: 'description',
        content:
          'Nama is a venture building and investment firm focusing on inventing, launching, and investing in innovative ventures. We work with startups and corporates to navigate through their growth plans.      ',
      },
      { name: 'robots', content: 'index, follow' },
    ]);
  }
}
