<div class="team pt-70">
  <div class="container">
    <div class="team__title text-center">{{ "team.title" | translate }}</div>

    <div
      class="row justify-content-center mt-5"
      [ngClass]="{ arabic: lang === 'ar' }"
    >
      <div class="col-lg-4 mt-3">
        <app-team-card
          imagePath="images/mohammed.svg"
          name="{{ 'team.member1name' | translate }}"
          position="{{ 'team.member1position' | translate }}"
          description="{{ 'team.member1description' | translate }}"
          linkedin="https://www.linkedin.com/in/alomarmohammed/"
          mail="Malomar@namainvest.sa"
        ></app-team-card>
      </div>

      <div class="col-lg-4 mt-3">
        <app-team-card
          imagePath="images/mouath.svg"
          name="{{ 'team.member2name' | translate }}"
          position="{{ 'team.member2position' | translate }}"
          description="{{ 'team.member2description' | translate }}"
          linkedin="https://www.linkedin.com/in/muadh-altuwaijri-26027b165/"
          mail="Maltuwaijri@namainvest.sa"
        ></app-team-card>
      </div>
    </div>
  </div>
</div>
