<div class="growth pt-70">
  <div class="container">
    <div class="wrapper">
      <div class="row" [ngClass]="{ arabic: lang === 'ar' }">
        <div
          class="growth__description col-lg-6 col-md-6 d-flex flex-column justify-content-center align-items-start"
        >
          <div class="growth__description__title">
            {{ "growth.title" | translate }}
          </div>
          <p class="growth__description__text mt-3">
            {{ "growth.description" | translate }}
          </p>
        </div>
        <div class="growth__image col-lg-6 col-md-6">
          <!-- <img src="assets/images/growth.svg" alt="growth" /> -->
          <ng-lottie
            [options]="options"
            (animationCreated)="animationCreated($event)"
          ></ng-lottie>
        </div>
      </div>
    </div>
  </div>
</div>
