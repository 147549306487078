import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangueService } from '../../shared/service/langue.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  lang: any;
  constructor(
    public translate: TranslateService,
    public langueService: LangueService
  ) {}

  ngOnInit(): void {
    this.langueService.getLangue().subscribe((res) => (this.lang = res));
  }
}
