<div class="footer">
  <div class="container">
    <div class="footer__navigation">
      <div class="logo mt-5">
        <a href="#">
          <img src="assets/icons/logo-icon.svg" alt="nama" />
        </a>
      </div>
      <div class="footer__navigation__links mt-5">
        <div>
          <a class="nav-link" href="#growth">{{
            "header.about" | translate
          }}</a>
        </div>
        <div>
          <a class="nav-link" href="#approach">{{
            "header.approach" | translate
          }}</a>
        </div>

        <div>
          <a class="nav-link" href="#services">{{
            "header.whatWeAre" | translate
          }}</a>
        </div>

        <div>
          <a class="nav-link" href="#team">{{ "header.team" | translate }}</a>
        </div>

        <div>
          <a class="nav-link" href="#contact">{{
            "header.contact" | translate
          }}</a>
        </div>
      </div>
    </div>
    <div
      class="footer__copyright mt-5 d-flex flex-row justify-content-between align-items-center"
      [ngClass]="{ reverse: lang === 'ar' }"
    >
      <div class="rights">{{ "footer.copyright" | translate }}</div>

      <div
        class="footer__copyright__social-media d-flex flex-row justify-content-between align-items-center"
      >
        <div>
          <a href="https://www.instagram.com/namainvest" target="_blank">
            <img src="assets/icons/instagram.svg" alt="instagram" />
          </a>
        </div>
        <div>
          <a href="https://www.linkedin.com/company/namainvest" target="_blank">
            <img src="assets/icons/linkedin-wh.svg" alt="linkedin" />
          </a>
        </div>
        <div>
          <a href="https://twitter.com/namainvest" target="_blank">
            <img src="assets/icons/twitter.svg" alt="twitter" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
